<template>
  <div class="news-template">
    <div class="header"></div>
    <div class="title">
      <h3>中国物品编码中心深圳分中心赴东莞调研进口冻品追溯数据互联互通工作</h3>
      <h5>2021-01-25 10:58:31 中国物品编码中心深圳分中心 练晓 苏巍 <i class="el-icon-reading"></i>89</h5>
    </div>
    <el-divider></el-divider>
    <div class="content">
      <div class="acticle-content" style="padding: 15px">
        <p
          style="
            box-sizing: border-box;
            margin-top: 0px;
            margin-bottom: 10px;
            line-height: 2em;
            color: rgb(69, 69, 69);
            text-indent: 2em;
            font-family: 'Microsoft YaHei', Tahoma, Helvetica, Arial, sans-serif;
            white-space: normal;
            background-color: rgb(255, 255, 255);
          "
        >
          1月7日下午，深圳市市场监督管理局指挥协调处与中国物品编码中心深圳分中心食品追溯团队赴东莞市集中监管仓、东莞市市场监督管理局就东莞进口冻品追溯监管情况调研，探讨深莞两地追溯码互认及数据互连互通工作。
        </p>
        <p
          style="
            box-sizing: border-box;
            margin-top: 0px;
            margin-bottom: 10px;
            line-height: 2em;
            color: rgb(69, 69, 69);
            text-indent: 2em;
            font-family: 'Microsoft YaHei', Tahoma, Helvetica, Arial, sans-serif;
            white-space: normal;
            background-color: rgb(255, 255, 255);
          "
        >
          座谈会上，深圳调研团队介绍了深圳市进口冻品追溯方案及目前进展情况、冷库视频监控试点、冷链食品臭氧消毒项目、深圳进口冻品追溯项目有关工作进展，建议实现深莞两地追溯信息的互联互通。东莞市市场监督管理局相关负责人介绍了东莞进口冻品追溯模式，对于入东莞流通的进口冻品，在东莞集中监管仓完成消毒、检测后，以集装箱为单位对每一件冻品赋予“溯源二维码”，内含报关单、检疫证、消毒证明和核酸检测证明等信息。
        </p>
        <p
          style="
            box-sizing: border-box;
            margin-top: 0px;
            margin-bottom: 10px;
            line-height: 2em;
            color: rgb(69, 69, 69);
            text-indent: 2em;
            font-family: 'Microsoft YaHei', Tahoma, Helvetica, Arial, sans-serif;
            white-space: normal;
            background-color: rgb(255, 255, 255);
          "
        >
          双方就东莞市进口冻品追溯数据与深圳市食品安全追溯系统数据互通、追溯码互认达成合作。深莞两地追溯码互认，对实现两地进口冻品追溯均具有积极意义，下一步将从有效打通前端追溯数据入手，加快推进系统的应用和对接工作。
        </p>
        <p
          style="
            box-sizing: border-box;
            margin-top: 0px;
            margin-bottom: 10px;
            line-height: 2em;
            color: rgb(69, 69, 69);
            text-indent: 2em;
            font-family: 'Microsoft YaHei', Tahoma, Helvetica, Arial, sans-serif;
            white-space: normal;
            background-color: rgb(255, 255, 255);
            text-align: center;
          "
        >
          <img
            src="/images/news.jpg"
            title="news.jpg"
            alt="news.jpg"
            style="
              box-sizing: border-box;
              border: 0px;
              vertical-align: middle;
              display: block;
              max-width: 100%;
              height: auto;
              margin: 0px auto;
            "
            data-catchresult="img_catchSuccess"
          />
        </p>
        <p
          style="
            box-sizing: border-box;
            margin-top: 0px;
            margin-bottom: 10px;
            line-height: 2em;
            color: rgb(69, 69, 69);
            text-indent: 2em;
            font-family: 'Microsoft YaHei', Tahoma, Helvetica, Arial, sans-serif;
            white-space: normal;
            background-color: rgb(255, 255, 255);
            text-align: right;
          "
        >
          （编辑：黄雪松）
        </p>
        <p><br /></p>
      </div>
    </div>
    <div class="newsfooter"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      contentText: `&nbsp;&nbsp;本报济南4月17日电  （记者徐锦庚、李蕊）今年是胜利油田发现60周年。位于黄河入海口的胜利油田迄今累计生产原油12.5亿吨，占全国同期陆上原油产量1/5，目前保持年稳产2340万吨以上。`,
    };
  },
};
</script>
<style lang="scss" scoped>
.news-template {
  text-align: center;
  width: 100%;
  font-family: Hiragino Sans GB, Microsoft Yahei, \\5fae\8f6f\96c5\9ed1, SimSun,
    \\5b8b\4f53, Arial;
  .header {
    height: 6em;
    background: #04c9a0;
  }
  h3 {
    font-size: 1.7em;
    margin: 0.7em 0;
  }
  h5 {
    margin: 0.5em 0;
    color: #909399;
  }
  .content {
    width: 75%;
    margin: 0 12%;
    p {
      font-size: 1.2em;
      text-align: left;
      line-height: 2.2;
      margin-bottom: 2em;
      overflow-wrap: break-word;
      word-wrap: break-word;
    }
  }
}
</style>